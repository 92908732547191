import React from "react";
import Seo from "../../components/SEO/SEO";
import Layout from "../../components/Layout/Layout";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { useStyles } from "../../index.styles";
import { StaticImage } from "gatsby-plugin-image";
import ButtonBackToHome from "../../components/Button/ButtonBackToHome";

const AboutPage = () => {
  const classes = useStyles();
  const breakpointsXs = useMediaQuery("(max-width: 1000px)");
  const breakpoints = useMediaQuery("(max-width: 765px)");

  return (
    <Layout>
      <Seo title="Giới thiệu" />
      <Box bgcolor="#f5f5f5">
        <Box
          width={breakpointsXs ? "95%" : "80%"}
          margin="auto"
          py={breakpointsXs ? 3 : 7}
        >
          <Box
            display={!breakpoints ? "flex" : undefined}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">
              <b>GIỚI THIỆU</b>
            </Typography>
            <Box my={2}>
              <ButtonBackToHome />
            </Box>
          </Box>
          <Box display={!breakpointsXs ? "flex" : undefined} mt={2}>
            <Box mt={4} width={breakpointsXs ? "100%" : "40%"}>
              <StaticImage src="../../images/thuNgo.png" alt="" />
            </Box>
            <Box
              width={breakpointsXs ? "100%" : "60%"}
              ml={breakpointsXs ? 0 : 2}
            >
              <Box mt={2}>
                <Box textAlign={"center"} fontSize={25} fontWeight={700}>
                  Thư ngỏ
                </Box>
                <Box fontSize={16} style={{ textIndent: "20px" }}>
                  Lời đầu tiên, thay mặt công ty trách nhiệm hữu hạn 2-9, tôi
                  trân trọng gửi tới quý khách hàng lời chào, lời chúc sức khoẻ
                  và lời cảm ơn chân thành. <br />
                  Công ty trách nhiệm hữu hạn 2-9 được thành lập ngày
                  28/08/2002. Tiền thân là Công ty 2-9.Tháng 8/2021 được đổi tên
                  thành Công ty trách nhiệm hữu hạn 2-9. Công ty chúng tôi
                  chuyên sản xuất cung cấp các sản phẩm giấy Kraft . Các loại
                  bao bì carton sóng B,C,E,3,5 và 7 lớp, in ấn gia công thùng
                  carton.
                  <br />
                  Sau hơn 20 năm đi vào hoạt động, với quy mô trên 10.000 m2,
                  cùng dây chuyền sản xuất hiện đại - công nghệ tiên tiến, đội
                  ngũ công nhân lành nghề được đào tạo trường lớp, thêm vào đó
                  là sự hoạt động hiệu quả của đội ngũ trẻ bản lĩnh - nhiệt
                  tình- năng động và đầy sức sáng tạo. Công ty trách nhiệm hữu
                  hạn 2-9 cam kết mang lại cho quý khách hàng những sản phẩm đạt
                  chất lượng tốt nhất, mẫu mã đa dạng, màu sắc in ấn tinh tế và
                  sắc nét. Thành công hôm nay không chỉ đến từ sự nỗ lực của tập
                  thể ban lãnh đạo, cán bộ, công nhân viên công ty trách nhiệm
                  hữu hạn 2-9, mà còn nhờ sự tín nhiệm của đối tác, khách hàng
                  thân thiết. Hơn ai hết, chúng tôi hiểu được tầm quan trọng của
                  sự hợp tác để cùng phát triển, đưa sản phẩm xuất xứ Việt Nam
                  vươn ra thị trường các nước.
                  <br />
                  Một lần nữa, thay mặt Công ty trách nhiệm hữu hạn 2-9, tôi xin
                  gửi lời tri ân chân thành nhất đến quý đối tác khách hàng và
                  hy vọng tiếp tục hợp tác hiệu quả với quý vị.
                  <br />
                  Trân trọng!
                </Box>
                <Box textAlign={"end"} fontWeight={700}>
                  <Box mr={4} fontSize={18}>
                    GIÁM ĐỐC
                  </Box>
                  <br />
                  <Box fontSize={16}>Nguyễn Trang Dung</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        width={breakpointsXs ? "100%" : "80%"}
        margin="auto"
        py={5}
        px={breakpoints ? 2 : 5}
        bgcolor="#e3edf3"
        my={breakpointsXs ? 0 : 8}
      >
        <Box fontSize={32}>
          <b>Sơ đồ tổ chức</b>
        </Box>
        <Box display="flex" justifyContent="center">
          <Box mt={4} width={breakpointsXs ? "100%" : "70%"}>
            <StaticImage
              className={classes.fullWidth}
              src="../../images/sodotochuc.png"
              alt="So Do To Chuc"
            />
          </Box>
        </Box>
      </Box>
      <Box width={breakpointsXs ? "95%" : "80%"} margin="auto" mt={4}>
        <Box fontSize={32}>
          <b>Một số hình ảnh công xưởng công ty trách nhiệm hữu hạn 2-9</b>
        </Box>
        <Box mt={3}>
          <Typography variant="h5">
            Dây chuyền sóng carton 3, 5, 7 lớp
          </Typography>
          <Box mt={2}>
            <StaticImage
              src="../../images/daychuyen1.jpg"
              alt="Day Chuyen Song"
            />
          </Box>
          <Box mt={2}>
            <StaticImage
              src="../../images/daychuyen2.jpg"
              alt="Day Chuyen Song"
            />
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="h5">Kho nguyên liệu</Typography>
          <Box mt={2}>
            <StaticImage
              className={classes.fullWidth}
              src="../../images/khonguyenlieu.png"
              alt="Kho Nguyen Lieu"
            />
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="h5">Xưởng SX Bao bì carton</Typography>
          <StaticImage src="../../images/gioiThieuA.jpg" alt="" />
          <Box my={2}>
            <StaticImage src="../../images/gioiThieuB.png" alt="" />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default AboutPage;
